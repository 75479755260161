export const withdrawalFields = [
    {
        prop: 'id',
        label: '序号',
        width: 50,
    },
    {
        prop: 'name',
        label: '代理类型名称',
    },
    {
        prop: 'draw_proportion',
        label: '代理抽成比例',
    },
    {
        prop: 'shop_count',
        label: '创建门店数',
    },
    {
        prop: 'agency_amount',
        label: '代理费金额',
    },
    {
        prop: 'duration',
        label: '有效时长',
    },
    {
        prop: 'add_time',
        label: '创建时间',
    },
    {
        prop: 'operate',
        label: '操作',
    },
]

export const formSchema = [
    {
        field: 'id',
        label: '代理类型ID',
        defaultValue: '0',
        component: 'Input',
        show: false,
    },
    {
        field: 'name',
        label: '代理类型名称',
        component: 'Input',
        required: true,
        style: {
            width: '100%'
        }
    },
    {
        field: 'DrawProportion',
        label: '代理分成比例',
        required: true,
        component: 'Input',
        defaultValue: '0.001',
        style: {
            width: '100%'
        }
    },
    {
        field: 'duration',
        label: '代理有效时长',
        component: 'Input',
        required: true,
        style: {
            width: '50%'
        }
    },
    {
        field: 'duration_type',
        label: '',
        component: 'Select',
        style: {
            width: '15%',
            marginLeft: '10px',
        },
        defaultValue: '1',
        componentProps: {
            options: [
                {
                    value: '1',
                    label: '天',
                },
                {
                    value: '2',
                    label: '月',
                },
                {
                    value: '3',
                    label: '年',
                },
            ]
        }
    },
]

export const withformSchema = [
    {
        field: 'type',
        label: '代理类型',
        component: 'Input',
        style: {
            width: '300px'
        }
    },
    {
        field: 'timer',
        label: '创建时间',
        component: 'el-date-picker',
        style: {
            width: '410px'
        },
        componentProps: {
            type: "daterange",
            startPlaceholder: "开始日期",
            endPlaceholder:"结束日期",
            valueFormat: 'yyyy-MM-dd'
        }
    },
]