<template>
    <div class="with">
        <div class="flex-shrink">
            <div class="query">
                <Form
                ref="form"
                :formSchema="withformSchema"
                >
                    <li style="width: 20%;" class="pl20">
                        <el-button type="primary" @click="onAjax">查询</el-button>
                        <el-button @click="onReset">重置</el-button>
                    </li>
                </Form>
            </div>
            <div class="operate">
                <div class="item" @click="onAjax">
                    <el-button icon="el-icon-refresh">刷新</el-button>
                </div>
                <div class="item">
                    <el-button icon="el-icon-plus" @click="onAdd">添加</el-button>
                </div>
                <div class="item">
                    <el-dropdown trigger="click" @command="onBatchDelete">
                        <el-button icon="el-icon-s-fold">
                            批量操作
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item icon="el-icon-delete" command="delete">删除</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <Table
        :tableFields="withdrawalFields"
        :total="total"
        :tableData="tableData"
        @selection-change="selectionChange"
        checked
        >
            <template #duration="{ row }">
                {{ row.duration }}{{ row.duration_type_name }}
            </template>
            <template #operate="{ row }">
                <el-button type="text" class="link b1" @click="onEdit(row)">编辑</el-button>
                <el-button type="text" class="link b2" @click="onDelete(row)">删除</el-button>
            </template>
        </Table>
        <DialogForm
        ref="dialog"
        :formSchema="formSchema"
        :title="title"
        width="600px"
        :labelWidth="130"
        @confirm="onConfirm"
        @handleCurrentChange="handleCurrentChange"
        >
        </DialogForm>
    </div>
</template>

<script>
    import { withdrawalFields, withformSchema, formSchema } from './index'
    import helper from '@/common/helper.js';
    export default {
        data(){
            return {
                title: '添加',
                formSchema,
                withformSchema,
                tableData: [],
                withdrawalFields,
                loading: true,
                total: 100,
                params: {
                    page: 1,
                    limit: 10,
                },
                select: []
            }
        },
        created(){
            
        },
        mounted(){
            this.onAjax()
        },
        methods: {
            onReset(){
                this.$refs.form.resetFields()
                this.onAjax()
            },
            handleCurrentChange(page){
                console.log(page);
            },
            selectionChange(item){
                this.select = item
            },
            onAjax(){
                this.$refs.form.validate((is, data)=>{
                    if(is){
                        const params = helper.timerHandle(data, 'timer', 'start_date', 'end_date')
                                this.$http.api('api/Agent/AgentClass', {
                                ...params,
                                ...this.params,
                            }
                        )
                        .then(res=>{
                            this.tableData = res.data.data
                            this.total = res.data.total
                        })
                        .finally(()=>{
                            this.loading = false
                        })
                    }
                })
            },
            onBatchDelete(command){
                if(command == 'delete'){
                    this.$http.api('api/Agent/AgentClassDel', {id: this.select.map(item=> item.id).join(',')})
                    .then(res=>{
                        this.$message.success(res.msg)
                        this.onAjax()
                    })
                }
            },
            onDelete({ id }){
                this.$confirm({
                    title: '提示',
                    content: '确定删除此信息吗？',
                    onOk: ()=>{
                        this.$http.api('api/Agent/AgentClassDel', { id })
                        .then(res=>{
                            this.$message.success(res.msg)
                            this.onAjax()
                        })
                    }
                })
            },
            onAdd(){
                this.title = '添加'
                this.$refs.dialog.onShow()
            },
            onEdit(item){
                this.title = '修改'
                this.$refs.dialog.onShow(true, item)
            },
            onConfirm(data){
                this.$http.api('api/Agent/AgentClassSave', data)
                .then(res=>{
                    this.$message.success(res.msg)
                    this.onAjax()
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .query{
        background: #fff;
        padding: 7px 0 7px 0;
        width: 100%;
    }
    .operate{
        display: flex;
        padding: 0 0 15px 15px;
        background-color: #fff;
        border-bottom: 1px solid #ebeef5;
        .item{
            margin-right: 10px;
        }
    }

    .link{
        color: #fff;
        font-size: 12px;
        padding: 4px 8px;
        border-radius: 2px;
        margin: 0 4px;
        &.b1{
            background: #1890ff;
        }
        &.b2{
            background: #999999;
        }
    }
    .with{
        background: #fff;
        flex-direction: column;
        display: flex;
        height: 100%;
        .flex-shrink{
            flex-shrink: 0;
        }
    }
</style>